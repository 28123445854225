

var baseUrl = '';
var ct_debug_check_stock = {};

switch (location.host) {
    case 'stg.vendobara-beta.com' :
        baseUrl     = 'http://stg-api.vendobara-beta.com';
        site_url    = 'http://stg.webstores.vendobara.com';
        break;
    case 'dev.vendobara-beta.com':
        baseUrl     = 'http://dev-api.vendobara-beta.com';
        gmaps_key   = 'AIzaSyBn1RNSRPsIiGOr_j4KG1hDxFu_RO_n0Kc';
        ct_debug_check_stock = {debug : 1 };
        //paypal_token_url  = 'http://dev-api.vendobara-beta.com/paypal/token/get';
        //paypal_site = 'http://dev.vendobara-beta.com/paypal/create-payment';
        site_url    = 'http://dev.vendobara-beta.com'
        break;
    case 'mivendobara.com':
            baseUrl     = 'http://localhost:8000';
            gmaps_key   = 'AIzaSyBn1RNSRPsIiGOr_j4KG1hDxFu_RO_n0Kc';
            //ct_debug_check_stock = {debug : 1 };
            //paypal_token_url  = 'http://dev-api.vendobara-beta.com/paypal/token/get';
            //paypal_site = 'http://dev.vendobara-beta.com/paypal/create-payment';
            site_url    = 'http://mivendobara.com/'
            break;
    case 'webstores.devduxtu.com':
        baseUrl     = 'http://apiwebstores.devduxtu.com';
        gmaps_key   = 'AIzaSyBn1RNSRPsIiGOr_j4KG1hDxFu_RO_n0Kc';
        //ct_debug_check_stock = {debug : 1 };
        //paypal_token_url  = 'http://dev-api.vendobara-beta.com/paypal/token/get';
        //paypal_site = 'http://dev.vendobara-beta.com/paypal/create-payment';
        site_url    = 'http://webstores.devduxtu.com'
        break;
    case 'webstores.vendobara.com':
        baseUrl = 'https://apiw.vendobara.com';
        gmaps_key   = 'AIzaSyBn1RNSRPsIiGOr_j4KG1hDxFu_RO_n0Kc';
        //paypal_token_url  = 'http://api.webstores.vendobara.com/paypal/token/get';
        //paypal_site = 'http://webstores.vendobara.com/paypal/create-payment';
        site_url    = 'https://webstores.vendobara.com'
        break;
    default :
        baseUrl = 'http://dev.vv.com/apiv1';
}
var token = null;
var myApp = angular.module('myApp', [
    'authService',
    'ngRoute',
    'LocalStorageModule',
    'ui.bootstrap',
    'ngMessages',
    'vcRecaptcha',
    'ngFileUpload',
    'satellizer',
    'uiGmapgoogle-maps',
    'rzModule',
    'paypal-button'
    ]);

myApp.config(['$routeProvider', '$locationProvider', '$authProvider','uiGmapGoogleMapApiProvider',
    function ($routeProvider, $locationProvider, $authProvider, uiGmapGoogleMapApiProvider ) {

        uiGmapGoogleMapApiProvider.configure({
            key: gmaps_key,
            v: '3.26', //defaults to latest 3.X anyhow
            libraries: 'visualization',
        });

        $authProvider.loginUrl = baseUrl + '/auth_login';

        /*
        $routeProvider.when('/', {
            templateUrl: 'templates/main/home.html',
            controller: 'homeController'
        });
        */

        $routeProvider.when('/recuperar-contrasena', {
            templateUrl: 'templates/users/passwordrecover.html',
            controller: 'userController',
        });

        $routeProvider.when('/reiniciar-contrasena', {
            templateUrl: 'templates/users/resetpassword.html',
            controller: 'recoverPasswordController',
        });

        /*$routeProvider.when('/crear-cuenta', {
            templateUrl: 'templates/users/createaccount.html',
            controller: 'userController',
        });*/

        $routeProvider.when('/login', {
            templateUrl: 'templates/users/login.html',
            controller: 'userController',
        });

        $routeProvider.when('/paypal', {
            templateUrl: 'templates/users/paypal.html',
            controller: 'userController',
            authenticated:true,
        });

        $routeProvider.when('/user/dashboard', {
            templateUrl: 'templates/users/dashboard.html',
            controller: 'dashboardController',
            authenticated: true
        });
        
        $routeProvider.when('/user/dashboard/pedidos-admin', {
            templateUrl: 'templates/adminorders/index.html',
            controller: 'orderController',
            authenticated: true
        });

        $routeProvider.when('/user/dashboard/pedidos-tienda', {
            templateUrl: 'templates/storeorders/index.html',
            controller: 'orderController',
            authenticated: true
        });

        $routeProvider.when('/user/dashboard/pedidos-tienda/:vb_order_id', {
            templateUrl: 'templates/storeorders/view.html',
            controller: 'orderController',
            authenticated: true
        });

        $routeProvider.when('/user/dashboard/pedidos-admin/:vb_order_id', {
            templateUrl: 'templates/adminorders/view.html',
            controller: 'orderController',
            authenticated: true
        });

        $routeProvider.when('/user/dashboard/pedidos', {
            templateUrl: 'templates/orders/index.html',
            controller: 'orderController',
            authenticated: true
        });

        $routeProvider.when('/user/dashboard/pedidos/:vb_order_id', {
            templateUrl: 'templates/orders/view.html',
            controller: 'orderController',
            authenticated: true
        });

        $routeProvider.when('/user/dashboard/usuarios', {
            templateUrl: 'templates/admin/users.list.html',
            controller: 'adminUsersController',
            authenticated: true
        });

        $routeProvider.when('/user/dashboard/usuarios/editar/:user_id', {
            templateUrl: 'templates/admin/users.edit.html',
            controller: 'adminUsersController',
            authenticated: true
        });

        $routeProvider.when('/user/dashboard/usuarios/agregar-usuario', {
            templateUrl: 'templates/admin/users.create.html',
            controller: 'adminUsersController',
            authenticated: true
        });

        $routeProvider.when('/user/dashboard/mensajes', {
            templateUrl: 'templates/users/messages.html',
            controller: 'messagesController',
            authenticated: true
        });
        $routeProvider.when('/user/dashboard/mensajes/conversacion/:thread_id', {
            templateUrl: 'templates/users/messagesthread.html',
            controller: 'messagesController',
            authenticated: true
        });

        $routeProvider.when('/user/dashboard/tienda/editar', {
            templateUrl: 'templates/stores/adminview.html',
            controller: 'storeController',
            authenticated: true
        });

        $routeProvider.when('/user/dashboard/tienda/editar/:store_id', {
            templateUrl: 'templates/stores/adminview.html',
            controller: 'storeController',
            authenticated: true
        });

        $routeProvider.when('/user/dashboard/admin/anuncios', {
            templateUrl: 'templates/admin/publicationads.list.html',
            controller: 'storePublicationController',
            authenticated: true
        });

        $routeProvider.when('/user/dashboard/anuncios', {
            templateUrl: 'templates/publication_ad/list.html',
            controller: 'storePublicationController',
            authenticated: true
        });

        $routeProvider.when('/user/dashboard/anuncios/crear-anuncio', {
            templateUrl: 'templates/publication_ad/create.html',
            controller: 'productController',
            authenticated: true
        });

        $routeProvider.when('/user/dashboard/tienda/agregar-producto', {
            templateUrl: 'templates/products/create.html',
            controller: 'productController',
            authenticated: true
        });

        $routeProvider.when('/user/dashboard/productos', {
            templateUrl: 'templates/products/admin_list.html',
            controller: 'storePublicationController',
            authenticated: true
        });

        $routeProvider.when('/user/dashboard/tienda/productos', {
            templateUrl: 'templates/products/list.html',
            controller: 'storePublicationController',
            authenticated: true
        });

        $routeProvider.when('/user/dashboard/anuncios/editar/:product_id', {
            templateUrl: 'templates/products/edit.html',
            controller: 'productController',
            authenticated: true
        });

        $routeProvider.when('/user/dashboard/tienda/producto/editar/:product_id', {
            templateUrl: 'templates/products/edit.html',
            controller: 'productController',
            authenticated: true
        });

        $routeProvider.when('/user/logout', {
            templateUrl: 'templates/users/logout.html',
            controller: 'userController',
            authenticated: true
        });

        $routeProvider.when('/user/dashboard/perfil', {
            templateUrl: 'templates/users/account.html',
            controller: 'userController',
            authenticated: true
        });

        $routeProvider.when('/user/dashboard/tiendas/', {
            templateUrl: 'templates/stores/list.html',
            controller: 'adminStoresController',
            authenticated: true
        });

        $routeProvider.when('/user/dashboard/direccion', {
            templateUrl: 'templates/users/address.html',
            controller: 'userController',
            authenticated: true
        });

        $routeProvider.when('/user/dashboard/crear-tienda', {
            templateUrl: 'templates/stores/create.html',
            controller: 'storeController',
            authenticated: true
        });

        $routeProvider.when('/publicacion/:product_id', {
            templateUrl: 'templates/products/product.html',
            controller: 'productController'
        });

        $routeProvider.when('/', {
            templateUrl: 'templates/publications/results.html',
            //reloadOnSearch : false,
            controller: 'productSearchController'
        });

        $routeProvider.when('/user/dashboard/admin/carousells/crear', {
            templateUrl: 'templates/carousell/create.html',
            controller: 'slidesCarousellController',
            authenticated : true,
        });

        $routeProvider.when('/user/dashboard/admin/carousells/editar/:id', {
            templateUrl: 'templates/carousell/edit.html',
            controller: 'slidesCarousellController',
            authenticated : true,
        });

        $routeProvider.when('/user/dashboard/admin/carousells', {
            templateUrl: 'templates/carousell/list.html',
            controller: 'slidesCarousellController',
            authenticated : true,
        });

        $routeProvider.when('/user/dashboard/categorias', {
            templateUrl: 'templates/categories/manage_categories.html',
            controller: 'manageCategoriesController',
            authenticated : true,
        });

        $routeProvider.when('/user/dashboard/admin/gestionar-filtros', {
            templateUrl: 'templates/manage_filters/manage_filters.html',
            controller: 'manageFiltersCategoriesController',
            authenticated : true,
        });

        $routeProvider.when('/user/dashboard/admin/gestionar-marcas', {
            templateUrl: 'templates/manage_brands/manage_brands.html',
            controller: 'manageBrandsController',
            authenticated : true,
        });

        $routeProvider.when('/user/dashboard/departamentos', {
            templateUrl: 'templates/departments/list.html',
            controller: 'manageDepartmentController',
            authenticated : true,
        });

        $routeProvider.when('/user/dashboard/departamentos/crear', {
            templateUrl: 'templates/departments/create.html',
            controller: 'manageDepartmentController',
            authenticated : true,
        });

        $routeProvider.when('/user/dashboard/departamentos/editar/:department_id', {
            templateUrl: 'templates/departments/edit.html',
            controller: 'manageDepartmentController',
            authenticated : true,
        });

        $routeProvider.when('/user/dashboard/wishlist', {
            templateUrl: 'templates/wishlist/index.html',
            controller: 'wishListController',
            authenticated : true,
        });

        $routeProvider.when('/tiendas', {
            templateUrl: 'templates/stores/results.html',
            controller: 'storeController'
        });

        $routeProvider.when('/tienda/:store_id', {
            templateUrl: 'templates/stores/store.html',
            controller: 'storeController'
        });

        $routeProvider.when('/cart', {
            templateUrl: 'templates/cart/cart-page.html',
            controller: 'cartController'
        });

        $routeProvider.when('/checkout/1', {
            templateUrl: 'templates/cart/checkout-step-1.html',
            controller: 'checkoutController'
        });
        $routeProvider.when('/checkout/2', {
            templateUrl: 'templates/cart/checkout-step-2.html',
            controller: 'checkoutController'
        });
        $routeProvider.when('/checkout/3', {
            templateUrl: 'templates/cart/checkout-step-3.html',
            controller: 'checkoutController'
        });
        $routeProvider.when('/checkout/4', {
            templateUrl: 'templates/cart/checkout-step-4.html',
            controller: 'checkoutController'
        });

        $routeProvider.when('/checkout/oxxopay-referenciapago/:vb_order_id',{
            templateUrl: 'templates/oxxopay/viewreference.html',
            controller: 'oxxoPayController',
            authenticated : true,
        });

        $routeProvider.when('/checkout/referencia-pago/:vb_order_id',{
            templateUrl: 'templates/cart/payment_reference.html',
            controller: 'directTransferController',
            authenticated : true,
        });

        //$routeProvider.when('/checkout/5/:vb_order_id', {
        $routeProvider.when('/checkout/paypal/pago/:vb_order_id', {
            templateUrl: 'templates/cart/checkout-step-5.html',
            controller: 'paypalPaymentsController'
        });
        $routeProvider.when('/checkout/conekta_credit_card/pago/:vb_order_id', {
            templateUrl: 'templates/cart/checkout-step-5-conekta.html',
            controller: 'conektaPaymentsController'
        });
        $routeProvider.when('/checkout/cancel', {
            templateUrl: 'templates/cart/checkout-step-cancel.html',
            controller: 'checkoutController'
        });
        $routeProvider.when('/bienvenido', {
            templateUrl: 'templates/users/welcomeregistration.html',
            controller: 'userController',
            authenticated: false
        });
        $routeProvider.when('/user/confirm/:activation_code', {
            templateUrl: 'templates/users/activationcomplete.html',
            controller: 'emailConfirmController',
            authenticated : false,
        });
        $routeProvider.when('/user/dashboard/pedidos-ct', {
            templateUrl: 'templates/orders_ct/index.html',
            controller: 'ctController',
            authenticated: true
        });
        $routeProvider.when('/user/dashboard/pedidos-ct/generar-pedido', {
            templateUrl: 'templates/orders_ct/generate.html',
            controller: 'ctController',
            authenticated: true
        });
        $routeProvider.when('/user/dashboard/pedidos-ct/:folio_ct', {
            templateUrl: 'templates/orders_ct/view.html',
            controller: 'ctController',
            authenticated: true
        });

        $locationProvider.html5Mode(true);

        $routeProvider.otherwise('/');


    }
]);

myApp.run(["$rootScope", "$location", "userModel", '$auth', function ($rootScope, $location, userModel, $auth) {
        $rootScope.$on("$routeChangeStart", function (event, next, current) {
            
            if(next.$$route){
                var authenticated =( next.$$route && next.$$route.authenticated !== undefined) ? next.$$route.authenticated : '';
                if (authenticated) {

                    if (!$auth.isAuthenticated()) {
                        $location.path('/');
                    }
                }

                var arrNotAllowedLogedIn = ['/login', '/crear-cuenta'];
                console.log("route",next.$$route );
                if (arrNotAllowedLogedIn.indexOf(next.$$route.originalPath) >= 0) {
                    if ($auth.isAuthenticated()) {
                        $location.path(current.$$route.originalPath);
                    }
                }
                console.log("current",current );
                if (current && current.$$route ){

                    var cur = current.$$route.originalPath;
                    var nxt = next.$$route.originalPath;
                    //not checkout continue
                    if (  cur.match(/^\/checkout/) &&
                            nxt.match(/^\/checkout/)
                        ){

                        if(
                            nxt.match(/^\/checkout\/[1-4]{1}$/)
                            && (
                                cur.match(/^\/checkout\/referencia-pago\/\:vb_order_id$/) ||
                                cur.match(/^\/checkout\/oxxopay-referenciapago\/\:vb_order_id$/) 
                                //|| cur.match(/^\/checkout\/paypal\/pago\/\:vb_order_id$/)
                                )
                        ) {
                            console.log('Why did you use history back?');
                            event.preventDefault();
                        }
                    }
                }
            }

        });

    }]);
//http://dev.vendobara-beta.com/checkout/referencia-pago/5aafecca107dc-5aafecca1081e
